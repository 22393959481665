export const URLS = {
    PUBLIC: {
        AUTH: {
            LOGIN: '/login',
            SIGNUP: '/signup',
            FORGOT_PASSWORD: '/password-reset',
        },
        APPLICATION: '/application',
        CONTACT: '/contact',
        JOIN: '/join',
        ROOT: '/',
        BLOG: '/blog',
        PRIVACY: '/privacy',
        TERMS: '/terms',
    },
    ADMIN: {
        DASHBOARD: '/admin',
        ACTIVITIES: {
            ROOT: '/admin/activities',
            CREATE: '/admin/activities/create',
        },
        APPLICATIONS: '/admin/applications',
    },
    COMMUNITY: '/community',
    CONNECT: {
        CALENDLY: '/connect/calendly',
        FACEBOOK: '/connect/facebook',
        TWITTER: '/connect/twitter',
        YOUTUBE: '/connect/youtube',
    },
    CONNECT_WITH_EXPERT: '/connect',
    CONTRIBUTE: '/contribute',
    GOVERNANCE: '/governance',
    HOME: '/home',
    LEADERBOARD: '/leaderboard',
    MEMBERS: '/members',
    NOTES: '/notes',
    OFFICE_HOURS: '/office-hours',
    POST: '/post',
    PROFILE: '/profile',
    SETTINGS: {
        HOME: '/settings',
        ACCOUNT: '/settings/account',
        OFFICE_HOURS: '/settings/office-hours',
        SECURITY_AND_PRIVACY: '/settings/privacy',
    },
    TREASURY: '/treasury',
    LOGOUT: '/auth/logout',
    /*
     * +---------------+
     * |  Action URLs  |
     * +---------------+
     */
    ACTION: {
        LOGOUT: '/actions/auth/logout',
        SET_THEME: '/actions/theme/set-theme',
        SUBSCRIBE_TO_NEWSLETTER: '/actions/marketing/subscribe-newsletter',
        PROFILE: {
            UPLOAD_IMAGE: '/actions/profile/upload-image',
        },
    },
};

const flattenUrls = (urls: object) => {
    interface IURLS {
        [key: string]: string | IURLS;
    }

    const extractUrls = (obj: IURLS): string[] => {
        const urls: string[] = [];

        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                urls.push(obj[key] as string);
            } else {
                urls.push(...extractUrls(obj[key] as IURLS));
            }
        }

        return urls;
    };

    return extractUrls(urls as IURLS);
};

export const getPublicURLS = () => {
    return flattenUrls(URLS.PUBLIC);
};
